import { Link } from "react-router-dom";
import styles from "./ImageButton.module.css"

export default function ImageButton({ url,src,onClick }) {
    return (
        <Link to={url} onClick={onClick}>
            <img
                className={styles.imgBtb}
                src={src}
            ></img>
        </Link>

    );
}