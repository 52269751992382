import styles from "./EmptyList.module.css";
import Appbar from "components/AppBar";
import ReturnButton from "components/ReturnButton";
import Footer from "components/Footer";
import { useNavigate } from "react-router-dom";

function EmptyList(){

    const navigate = useNavigate();

    return(
        <section className={styles.container}>
          <Appbar
              titulo={"Encomendas"}
              btnVoltar={
                  <ReturnButton
                      onClick={() => { navigate('/') }}
                      src={`${process.env.PUBLIC_URL}/icons/return.png`}
                      alt={"ReturnIcon"}
                  ></ReturnButton>
              }
          ></Appbar>
          <div className={styles.containerComponents}>
              <div className={styles.errormsg}>Nenhum pedido encontrado.</div>
         
          </div>
      </section>
    );
}

export default EmptyList;