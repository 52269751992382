// Import Styles
import styles from "./PageFoods.module.css";

//Import Components
import Spinner from "components/Spinner";
import Appbar from "components/AppBar";
import ReturnButton from "components/ReturnButton";
import Filter from "components/Filter";
import ProductList from "components/ProductList";

// Import Hooks
import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";

// Import Libraries
import Swal from "sweetalert2";

// Import Services
import { formataString } from '../../services/UtilityFunctions';
import { useFoodEffects, atualizarQuantidade, useSalvarValores,limparValores, filtroProdutos } from "services/PageFoodsServices";

function PageFoods() {

  //useState refatorado
  const [state, setState] = useState({
    foods: [], // recebe o localstorage alimentos (produtos adicionados no pedido)
    filterFoods: [], // alimentos filtrados
    groupDescription: '', // recebe o desgru(Nome dos Grupos)
    search: '', // pesquisa
    loading: true, // estado de carregamento
  });

  // Navegação entre páginas
  const navigate = useNavigate();

  // Recupera o id/codgru da url 
  const { id } = useParams();

  // Chama o efeito Exibir os produtos passando `id` e `setState`
  useFoodEffects(id, setState);

  // Função para mudar o valor da quantidade
  const funcaoAtualizarQuantidade = (codpro, novaQuantidade) =>{
    atualizarQuantidade(setState, codpro, novaQuantidade);
  }

  // chama a função salvar alteração
  const salvarValores = useSalvarValores(state);

  // chama Função limpar pedido
  const limparQuantidade = limparValores(state, setState, id);

  // Filtra alimentos pelo codgru e salva no array alimentosFiltrados
  const alimentosFiltrados = state.foods.filter(alimento => alimento.codgru === parseInt(id));

  // função filtra pedido por nome
  const filtroDeProdutosPorNomes = filtroProdutos(state, setState, alimentosFiltrados);

  // Dispara a função filtro de nome
  useEffect(() => {
    filtroDeProdutosPorNomes();
    // Esse efeito sera disparado sempre que:
    // state.search: Quando o valor da busca (input de texto) muda
    // state.foods: Quando os alimentos disponíveis mudam (por exemplo, ao carregar novos alimentos), o filtro é aplicado novamente.
    // id: Quando o id muda (Para cada grupo de alimentos)
  }, [state.search, state.foods, id]);

  // recebe true caso todos os alimentos tenham qtdpro 0 e false se ao menos um protuto tenha qtdpro > 0.
  const verificaPedidoVazio = () => {
    // every : retorna um bool para a condicional (qtdpro === 0)
    return alimentosFiltrados.every(alimento => alimento.qtdpro === 0);
  };

  return (
    <>
      <section className={styles.container}>
        {/* Cabeçalho - appbar */}
        <Appbar
          btnVoltar={
            <ReturnButton
              onClick={() => { navigate('/Grupos') }}
              src={`${process.env.PUBLIC_URL}/icons/return.png`}
              alt={"ReturnIcon"}
            />
          }
          titulo={formataString(state.groupDescription)}
        />

        {/* Condicional Spinner */}
        {state.loading ? (
          <div className={styles.Spinner}>
            <Spinner />
          </div>
        ) :
          (
            <div> 
              <div className={styles.containerFilter}>
                <div>
                  {/* Filtro */}
                  <Filter
                    className={styles.inputFilter}
                    type={"text"}
                    value={state.search}
                    onChange={(e) => setState(prevState => ({ ...prevState, search: e.target.value }))}
                    placeholder={"Filtre os itens desejados"}
                  />

                  {/* Botao salvar pedido */} 
                  <button className={styles.buttonSave} onClick={() => {
                    if (verificaPedidoVazio()) {
                      Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Nenhum item adicionado.",
                      });
                    } else {
                      salvarValores();
                    }
                  }}
                  > <img className={styles.iconCheck}
                    src={`${process.env.PUBLIC_URL}/icons/check.png`}
                    alt="Ícone"
                    />
                  </button>

                  {/* Botao limpar pedido */}
                  <button className={styles.buttonClear} onClick={limparQuantidade}>
                    <img className={styles.iconTrash}
                      src={`${process.env.PUBLIC_URL}/icons/trash.png`}
                      alt="Ícone"
                    />
                  </button>
                </div>
              </div>
              {/* Verifica se tem alimentos no grupo */}
              {state.filterFoods.length === 0 ? (

                <h2 className={styles.errormsg}>Sem alimentos disponíveis.</h2>
                ) :
                (
                  <div>
                    {/* Listagem dos produtos */}
                    <div className={styles.containerList}>
                      {/* Mapeia os produtos */}
                      {state.filterFoods.map((alimento) => (

                        // chave codpro : identifica de forma única cada item na lista de produtos
                        <div className={styles.containerItem} key={alimento.codpro}>
                          {/* Lista dos produtos existentes */}
                          <ProductList
                            nomeProduto={alimento.despro}
                            inputTitulo={"Quantidade"}
                            inputType={"number"}
                            inputMin={"0"}
                            inputValue={alimento.qtdpro}
                            inputOnchange={(e) => funcaoAtualizarQuantidade(alimento.codpro, parseFloat(e.target.value))}
                            perecivel={formataString(alimento.perecivel)}
                            medida={formataString(alimento.unimed)}
                            fator={formataString(alimento.fator)}
                          ></ProductList>
                        </div>
                       ))
                      }
                    </div>
                  </div>
                )
              }
            </div>
          )
        }
      </section>
    </>
  );
}

export default PageFoods;
