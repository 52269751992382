// Import Hooks
import { useEffect, useState } from 'react';

// Import API
import {GRUPOS_URL, PEDIDOS_URL} from '../api/index';

// Import Libraries
import Swal from 'sweetalert2';

// Import Services
import { dadosUsuario } from './UtilityFunctions';


// Função enviar pedidos 
export const enviarPedido = async (navigate, setAtualizaCheck, atualizaCheck) => {
  // Salva o array recebido de alimentos (alimentos salvos no pedido) em storedAlimentos
  const storedAlimentos = JSON.parse(localStorage.getItem('alimentos'));

  // Verifica se o pedido está vazio e exibe um erro
  if (!Array.isArray(storedAlimentos) || storedAlimentos.length === 0) {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Não é possível enviar um pedido vazio.",
    });
    return null;
  }

  // Filtra os alimentos que possuem uma quantidade maior que 0
  const pedidos = storedAlimentos.filter(item => item.qtdpro > 0);

  // Verifica se o pedido filtrado está vazio e exibe um erro
  if (pedidos.length === 0) {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Não é possível enviar um pedido vazio.",
    });
    return null;
  }

  // Recupera os valores de Wemp, Licença e Token
  const { storedWemp, storedLicense, storedToken } = dadosUsuario();

  // Bloqueia pedidos sem wemp, licença e token
  if (!storedWemp || !storedLicense || !storedToken) {
    console.error("Token, Wemp ou License não encontrado.");
    Swal.fire({
      icon: "error",
      title: "Erro",
      text: "Token, Wemp ou License não encontrado.",
    });
    return null;
  }

  // Solicita ao usuário que selecione um curso
  const { value: nomeCurso } = await Swal.fire({
    title: "Selecione o curso",
    input: "radio",
    inputOptions: {
      "Educação Infantil": "Educação Infantil",
      "Ensino Fundamental": "Ensino Fundamental",
      "Estado": "Estado",
      "Educação Infantil e Fundamental": "Educação Infantil e Fundamental",
    },
    inputValidator: (value) => {
      if (!value) {
        return "Você precisa selecionar uma opção!";
      }
    },
    confirmButtonText: "Confirmar",
    confirmButtonColor: "#3085d6",
    cancelButtonText: "Cancelar",
    cancelButtonColor: "#d33",
    showCancelButton: true,
    customClass: {
      popup: 'swal-custom-popup',
      input: 'swal-custom-radio'
    },
  });

  // Verifica se o usuário cancelou o SweetAlert
  if (!nomeCurso) {
    Swal.fire({
      icon: "error",
      title: "Cancelado",
      text: "Pedido não enviado.",
      confirmButtonColor: "#3085d6",
    });
    return null;
  }

  // Configura os headers para o envio do token
  const headers = {
    'Authorization': `Bearer ${storedToken}`,
    'Content-Type': 'application/json',
  };

  // Cria um array de promessas para enviar cada pedido
  const promises = pedidos.map(pedido => {
    const { codpro, qtdpro } = pedido;

    // Formata o pedido para ser enviado
    const pedidosFormatados = {
      codped: 0, // Código do pedido é AutoIncrement
      wemp: storedWemp, // Envia o wemp resgatado
      datped: new Date().toLocaleDateString('en-CA'), // Envia a data atual
      codpro, // Envia o Codpro sem fazer alterações
      qtdped: qtdpro, // Recebe a quantidade alterada(qtdpro) e envia para qtdped
      licenca: storedLicense, // Envia a licença resgatada
      curso: nomeCurso, // Envia o curso selecionado
    };

    // Retorna uma promessa para o envio do pedido
    return fetch(`${PEDIDOS_URL}`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(pedidosFormatados),
    });
  });

  // Aguarda que todas as promessas sejam resolvidas ou rejeitadas
  return Promise.all(promises)
    .then(responses => {
      // Verifica se todas as requisições foram bem-sucedidas
      const allSuccessful = responses.every(response => response.ok);

      if (allSuccessful) {
        // Limpa o localStorage de alimentos
        localStorage.removeItem('alimentos');

        // Mensagem de sucesso
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Seu pedido foi enviado com sucesso!",
          showConfirmButton: false,
          timer: 1200
        }).then(() => {
          // Atualiza o estado
          setAtualizaCheck(!atualizaCheck);
          // Navega para a página inicial
          navigate('/');
        });
      } else {
        throw new Error('Erro ao enviar um ou mais pedidos');
      }
    })
    .catch(error => {
      console.error('Erro ao enviar o pedido:', error);
      Swal.fire({
        icon: "error",
        title: "Erro",
        text: "Não foi possível enviar o pedido.",
      });
    });
};


// Função Cancelar pedido
export const apagar = (setAtualizaCheck, atualizaCheck) => {
    // Limpa o localstorage Alimentos
    localStorage.removeItem('alimentos');
    // Envia nulo para o estado atualizaCheck
    setAtualizaCheck(!atualizaCheck); 
    Swal.fire({
      position: "center",
      icon: "success",
      title: "Todos os Itens foram removidos!",
      showConfirmButton: false,
      timer: 1200
    });
  };

/// Funcao para selecionar imagem do card
 export function card() {
    //Recupera o valor de familia
    const familia = localStorage.getItem('familia'); 
    let imgcard;
    
    // Opções de familias
    switch (familia) {
      case 'estocaveis':
        imgcard = "imagens/iconEstocaveis.png";
        break;
      case 'hortifruti':
        imgcard = 'imagens/iconHortifruti.png';
        break;
      case 'pereciveis':
        imgcard = 'imagens/iconPereciveis.png';
        break;
      case 'dieta especial':
        imgcard = 'imagens/iconDieta.png';
        break;
      default:
        imgcard = 'imagens/iconEstocaveis.png';
        break;
    }
  
    return imgcard;
  }

/// Hook para recuperar os grupos
  export const useGruposPorFamilia = (atualizaCheck) => {
    // Estado que controla o carregamento dos dados
    const [loading, setLoading] = useState(true);

    // Estado que indica se a família foi encontrada
    const [familiaEncontrada, setFamiliaEncontrada] = useState(false);
    
    // Estado que armazena os grupos retornados pela API
    const [grupos, setGrupos] = useState([]);
  
    // useEffect para buscar grupos por família, executado sempre que o valor de atualizaCheck mudar
    useEffect(() => {
      const GetGruposPorFamilia = async () => {
        // Recupera o token armazenado no localStorage
        const { storedToken } = dadosUsuario();
  
        // Função que define o timeout de 10 segundos para a requisição
        const TimeOut = (url, options, timeout = 10000) => {
          return Promise.race([
            fetch(url, options),
            new Promise((_, reject) =>
              setTimeout(() => reject(new Error("Request timeout")), timeout)
            )
          ]);
        };
  
        try {
          // Verifica se o token está presente
          if (!storedToken) {
            console.error("Token não encontrado.");
            return;
          }
  
          // Cabeçalhos da requisição, incluindo o token de autorização
          const headers = {
            'Authorization': `Bearer ${storedToken}`,
            'Content-Type': 'application/json',
          };
  
          // Recupera o valor salvo em 'familia' no localStorage
          const familia = localStorage.getItem('familia');
  
          // Adiciona timeout à requisição para buscar grupos pela família
          const response = await TimeOut(`${GRUPOS_URL}/familia/${familia}`, { headers });
          
          // Transforma a resposta em JSON
          const data = await response.json();
  
          // Verifica se o array 'data' é válido e não está vazio
          if (!Array.isArray(data) || data.length === 0) {
            // Se a família não foi encontrada ou o array está vazio, atualiza o estado para false
            setFamiliaEncontrada(false);
          } else {
            // Se os grupos foram encontrados, atualiza o estado com os grupos
            setGrupos(data);
            setFamiliaEncontrada(true);
          }
        } catch (erro) {
          // Em caso de erro na requisição, exibe o erro no console e atualiza o estado para false
          console.error("Erro ao carregar dados:", erro);
          setFamiliaEncontrada(false);
        } finally {
          // Independentemente do sucesso ou falha, encerra o carregamento
          setLoading(false);
        }
      };
  
      // Invoca a função de busca de grupos por família
      GetGruposPorFamilia();
  
    // Dependência: atualizaCheck define quando o useEffect será disparado novamente
    }, [atualizaCheck]);
  
    // Retorna os estados que o hook controla para serem utilizados no componente
    return { loading, familiaEncontrada, grupos };
  };
